@use "src/styles/mixins" as mixin;

.logo {
  @include mixin.flex(center, center);
  min-height: 100%;
  svg {
    width: 100px;
    height: 100px;
  }
}

.fullLoader {
  min-height: 100vh;
}

.smallLogo {
  svg {
    width: 44px;
    height: 44px;
  }
}
