@import "~styles/theme.scss";

$halfSpace: calc($space / 2);

.differenceTag {
  padding: $halfSpace $space;
  margin: 0px auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  min-width: 72px;
  border-width: 1px;
  border-style: solid;
  border-radius: $halfSpace;

  &__text {
    margin-left: 6px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }

  &--positive {
    color: $i-day-primary-green;
    background-color: rgba($i-day-primary-green, 0.06);
    border-color: $i-day-primary-green;

    svg {
      transform: rotate(180deg);
    }
  }

  &--negative {
    color: $i-day-primary-red;
    background-color: rgba($i-day-primary-red, 0.06);
    border-color: $i-day-primary-red;
  }
}
