@use "src/styles/colors" as color;
@import "../../../../styles/theme.scss";

.drivers-modal-wrapper {
  .header {
    display: grid;
    grid-template-columns: 20px 1fr 1fr;
    padding-bottom: 10px;
    // border-bottom: 1px solid $i-day-grey-5;

    div {
      color: $i-day-grey-2;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.08em;
      text-align: center;
      &:first-child {
        width: 150px;
        text-align: left;
      }
      .ant-checkbox-wrapper {
        margin-right: 5px;
      }
    }
  }
  .row {
    display: grid;
    grid-template-columns: 20px 1fr 1fr;
    border-bottom: 1px solid $i-day-grey-5;
    // padding: 0 32px;
    &:nth-child(odd) {
      background-color: $i-day-primary-white;
    }
    &:last-child {
      border-bottom: none !important;
    }
  }
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0 !important;
    color: $i-day-grey-0;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    &:first-child {
      justify-content: flex-start;
      .ant-checkbox-wrapper {
        margin-right: 10px;
        .ant-checkbox-input:focus + .ant-checkbox-inner,
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner {
          border-color: color.$primary-color;
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: color.$primary-color;
            border-color: color.$primary-color;
          }
        }
      }
    }
  }
}
