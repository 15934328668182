@import "../../../../styles/theme.scss";

.badgeList {
  display: flex;
  gap: $space;
}

.badge {
  padding: 0 $space;
  font-size: 13px;
  line-height: $space-3;
  background: $i-day-primary-yellow;
  border-radius: $input-border-radius;
}

.warning {
  color: $i-day-black;
  background: $i-day-primary-yellow;
}

.danger {
  color: $i-day-primary-white;
  background: $i-day-primary-red-darker;
}
