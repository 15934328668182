@use "src/styles/mixins" as mixin;
@import "../../../../../styles/theme.scss";

.declinement-drawer-wrapper {
  .ant-drawer-wrapper-body {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      p {
        font-style: normal;
        color: $i-day-grey-1;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
      }
      .ant-select {
        background: $i-day-grey-8;
        // padding: 12px;
        border: 1px solid $i-day-grey-4;
        border-radius: 6px;
        // height: 44px;
        .ant-select-selector {
          background: $i-day-grey-8;
          border: none;
          // height: 44px;
          &:focus {
            border: none;
            box-shadow: none !important;
          }
        }
      }
      .decline-reason {
        margin-top: 20px;
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: $i-day-grey-1;
        }
        .ant-input {
          margin-top: 10px;
          padding: 12px;
          gap: 8px;
          background: $i-day-grey-8;
          border: 1px solid $i-day-grey-4;
          border-radius: 6px;
        }
      }
      .ant-btn {
        border-color: $i-day-primary-green;
        background-color: $i-day-primary-green;
        color: $i-day-primary-white;
        font-size: 15px;
        margin-top: 20px;
        height: 48px;
        @include mixin.flex(center, center);
        border-radius: 6px;

        &:disabled {
          border-color: $i-day-grey-4;
          background-color: $i-day-grey-4;
        }
      }
    }
  }
}
