.homeWrapper {
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }

  @media (min-width: 1280px) {
    gap: 24px;
  }
}

.balanceWrapper {
  @media (min-width: 1000px) {
    flex-grow: 2;
  }
  @media (max-width: 999px) {
    margin-bottom: 16px;
  }
}

.earningsWrapper {
  @media (min-width: 1000px) {
    min-width: 400px;
  }
}
