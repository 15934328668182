@mixin button($backgroundColor, $hoverColor, $textColor: fff) {
  background-color: $backgroundColor !important;
  color: $textColor !important;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $hoverColor !important;
    color: $textColor;

    .mondayButtonDivider {
      background-color: $backgroundColor !important;
    }
  }
}

@mixin text(
  $font-size,
  $font-weight,
  $letter-spacing,
  $color,
  $text-transform: none,
  $opacity: 1,
  $important: false
) {
  @if $important == true {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    opacity: $opacity !important;
    text-transform: $text-transform !important;
    letter-spacing: $letter-spacing !important;
    color: $color !important;
  }
  @if $important == false {
    font-size: $font-size;
    font-weight: $font-weight;
    opacity: $opacity;
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
    color: $color;
  }
}

@mixin flex($align-items, $justify-content, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin textEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin square($width, $height, $radius, $background: $i-day-primary-white, $opacity: 1) {
  width: $width;
  height: $height;
  border-radius: $radius;
  opacity: $opacity;
  background-color: $background;
}

@mixin circle($size, $color, $opacity: 1) {
  @include square($size, $size, 50%, $color, $opacity);
}

@mixin transition($time) {
  transition: all $time ease-in-out;
}

@mixin absolute(
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $position: absolute
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
