@import "../../styles/theme.scss";

.vehicleDocumentsListWrapper {
  margin-top: $space-3;
}

.vehicleDocumentsItemWrapper {
  &:nth-of-type(n + 2) {
    margin-top: $space-2;
  }
}

.ctaBtnWrapper {
  margin: 0 auto;
  width: 300px;
  display: flex;
}
