@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.userslist-wrapper {
  & * {
    font-family: Inter, sans-serif;
  }
  overflow: hidden;
  height: 100%;
  @include mixin.flex(center, flex-start, column);
  .header {
    display: grid;
    grid-template-columns: 48px repeat(2, 1fr);
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $i-day-grey-5;
    padding: 32px;
    h1 {
      margin: 0 0 0 16px;
      color: $i-day-grey-0;
      font-size: 26px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: -0.02em;
    }

    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 6px;
      background: $i-day-grey-8;
      & input {
        background: $i-day-grey-8;
      }
      @media (min-width: 1120px) {
        max-width: 300px;
      }
      border-color: $i-day-grey-5;
      border: 1px solid $i-day-grey-5;
      box-shadow: none;
      margin-left: auto;
    }

    @media (max-width: $breakpoint-md) {
      grid-template-columns: 48px 1fr;
      padding: 24px;
      & h1 {
        margin-left: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }
      & > .ant-input-affix-wrapper {
        width: 100% !important;
        margin-top: 24px;
        grid-column: 1 / -1;
      }
    }
  }
  .invitation-wrapper {
    width: 600px;
    @media (max-width: 900px) {
      width: 100%;
    }
    margin-top: 32px;
    align-self: center;
    h2 {
      font-size: 20px;
      color: $i-day-grey-0;
      font-weight: 600;
      padding-bottom: 15px;
      border-bottom: 1px solid $i-day-grey-5;
      @media (max-width: $breakpoint-sm) {
        font-size: 17px;
        line-height: 25px;
        letter-spacing: -0.005em;
      }
    }
    p {
      margin-top: 15px;
    }
    & > p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $i-day-black;
      margin-bottom: 16px;

      @media (max-width: $breakpoint-sm) {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
    }
    .ant-form {
      .ant-form-item-label > label {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: $i-day-grey-1;

        @media (max-width: $breakpoint-sm) {
          font-size: 13px;
          line-height: 19px;
        }
      }
      .ant-input {
        min-height: 44px;
        border-radius: 6px;
      }
      .ant-btn {
        @include mixin.flex(center, center);
        height: 40px;
        color: $i-day-primary-green;
        border-color: $i-day-primary-green;
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 6px;

        @media (max-width: $breakpoint-sm) {
          width: 100%;
          height: 48px;
        }
      }
    }

    .ant-select-multiple,
    .ant-select-single {
      .ant-select-selector {
        min-height: 44px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        .ant-select-selection-item {
          height: 30px;
          @include mixin.flex(center, flex-start);
        }
      }
    }
  }
  .infinite-scroll-component__outerdiv {
    background-color: $i-day-grey-8;
    .infinite-scroll-component {
      @include mixin.flex(center, flex-start, column);
    }
    @media (max-width: $breakpoint-sm) {
      background-color: $i-day-primary-white;
    }
  }
  .userlist-section {
    width: 100%;
    flex: 1;
    overflow: hidden;

    @media (max-width: 900px) {
      & .users-list,
      .invitation-wrapper {
        padding: 0 20px;
        padding-bottom: 16px;
      }
    }
    @media (max-width: $breakpoint-sm) {
      & .users-list,
      .invitation-wrapper {
        padding: 0 16px;
        padding-bottom: 16px;
      }
    }
  }
  .users-list {
    width: 600px;
    margin-top: 15px;
    @media (max-width: 900px) {
      width: 100%;
    }
    .header {
      @include mixin.flex(center, space-between);
      padding: 0 0 15px 0;
      margin-bottom: 15px;
      h2 {
        font-size: 20px;
        color: $i-day-grey-0;
        font-weight: 600;
        margin-bottom: 0;

        @media (max-width: $breakpoint-sm) {
          font-size: 17px;
          line-height: 25px;
          letter-spacing: -0.005em;
          margin-bottom: 8px;
        }
      }
      .ant-input-affix-wrapper {
        width: 280px;
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
      }
    }
    padding-bottom: 16px;
  }
}

.userslist-wrapper-loading {
  min-height: calc(100vh - 100px);
  & .loading-logo {
    margin-top: auto;
    margin-bottom: auto;
  }
}
