@import "../../styles/theme.scss";

.documentScreenWrapper {
  margin: auto;
  padding: 24px;
  background: $i-day-primary-white;
  border-radius: 12px;
  overflow: auto;
  max-height: calc(100vh - 130px);

  @media (max-width: $breakpoint-sm) {
    padding-top: 24px;
    padding: 16px;
    &.fullHeight {
      height: 100vh;
    }
  }

  @media (min-width: 1280px) {
    margin-top: 24px;
  }
}
