@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.bookingItemRowWrapper {
  margin-top: 32px;
}

.bookingItemColWrapper {
  background-color: $i-day-primary-white;
  border-radius: 8px;
  padding: 15px 0;
}

.bookingItemSubTopWrapper {
  background-color: $i-day-grey-7;
  padding-left: 20px;

  :global(.subTopWrapper__header) {
    font-size: 20px !important;
  }
}

.driverBookingLogo {
  min-height: 400px;
}

.footer {
  width: 100%;
  @include mixin.flex(center, space-between);
  padding: 16px 24px;
  border-radius: 8px;
  background-color: $i-day-primary-white;
  margin-top: 20px;
  :global(button) {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    width: 100px;
    background-color: $i-day-primary-green;
  }
}
