@import "../../styles/theme.scss";

.disclaimer {
  font-size: 13px;
  color: $i-day-grey-1;
}

.link {
  a {
    color: $i-day-primary-green;
    cursor: pointer;
  }
  :hover a {
    color: $i-day-primary-green-darker;
    &:active,
    &:focus {
      color: $i-day-primary-green-lighter;
    }
  }
}
