@import "../../styles/theme.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 0;
  color: $i-day-grey-0;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.content {
  margin-top: 20px;
  overflow: hidden;
  border: 1px solid $i-day-grey-6;
  border-radius: 8px;
}

.badge {
  box-sizing: border-box;
  padding: 21px;
  background: $i-day-grey-7;
  font-family: Inter, sans-serif;
}

.badgeLabel {
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 4px;
  color: $i-day-grey-1;
}

.badgeValue {
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 34px;
  color: $i-day-grey-0;
  margin-bottom: 0;
}

.dayPickerWrapper {
  padding-top: 20px;
}

.dayPicker {
  display: flex;
  justify-content: center;
}

.footer {
  margin-top: 20px;
}
