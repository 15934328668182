@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.walletLayoutHeader {
  padding: 46px $space-5;
  width: 100%;
  background-color: $i-day-primary-white;
  border-radius: $space;
}
@media (max-width: $breakpoint-sm) {
  .stripeStatusMobile {
    margin-left: auto;
  }
  .activeMenuIndicator {
    margin-bottom: 16px;
    width: 100%;
    @include mixin.flex(center, flex-start);
    h1 {
      margin: 0 0 0 15px;
      font-weight: 600;
    }
  }
}
