@import "../../../styles/theme.scss";

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badge {
  border-radius: 6px;
  padding: 3px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: $i-day-primary-blue;
}

.warningBadge {
  background: #fff2e7;
  color: $i-day-primary-yellow;
}

.infoBadge {
  color: $i-day-primary-blue;
  background: #e7f3ff;
}
