@import "../../../styles/theme.scss";

.profilePageContainer {
  @media (min-width: 1280px) {
    margin-top: 24px;
  }

  padding: 24px;
  background: $i-day-primary-white;
  border-radius: 12px;
  font-family: Inter, sans-serif;

  & p {
    margin-bottom: 0;
  }
}
