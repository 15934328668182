@import "../../styles/theme.scss";

.link {
  color: $i-day-primary-green-darker;
  font-weight: 500;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $i-day-primary-green-darkest;
    text-decoration: none;
  }

  &:focus-visible {
    outline: 1px solid $i-day-primary-green-darkest;
    text-decoration: none;
    outline-offset: 4px;
    border-radius: 6px;
  }

  &:active {
    color: $i-day-primary-green-darkest;
    text-decoration: none;
  }
}
