@import "../../styles/theme.scss";

.icabbi-bottom-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
  }

  .ant-drawer-content {
    border-radius: 12px 12px 0 0;
  }
}
