@import "../../styles/theme.scss";

.customSearchbar {
  &:global(.ant-input-affix-wrapper) {

    width: 100%;
    height: 44px;
    border-radius: 6px;

    &:global(.ant-input-affix-wrapper:hover) {
      border-color: $i-day-grey-0;
    }
    &:global(.ant-input-affix-wrapper-focused) {
      border-color: $i-day-grey-0;
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%);
    }
  }
}
