@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.custom-dropdown-wrapper {
  position: relative;
  @include mixin.flex(center, center);
  svg:hover {
    cursor: pointer;
  }
  .options {
    display: block;
    position: absolute;
    min-width: 190px;
    width: auto;
    top: calc(100% + 0.1rem);
    right: 0;
    background-color: $i-day-primary-white;
    border-radius: 6px;
    border: 1px solid $i-day-grey-5;
    z-index: 2;
    filter: drop-shadow(3px 0px 3px rgba(0, 0, 0, 0.04));
    .option {
      @include mixin.flex(center, space-between);
      padding: 8px 20px;
      border-bottom: 1px solid $i-day-grey-5;
      cursor: pointer;
      &:hover {
        background-color: $i-day-grey-5;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      h4 {
        margin: 0;
        color: $i-day-grey-0;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.3;
      }
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
