// TODO: Agree naming convention with design/dev
// Initial colour vars
$i-day-black: #000000;

$i-day-grey-0: #333333;
$i-day-grey-1: #888888;
$i-day-grey-2: #aaaaaa;
$i-day-grey-3: #cccccc;
$i-day-grey-4: #dddddd;
$i-day-grey-5: #eeeeee;
$i-day-grey-6: #f2f2f2;
$i-day-grey-7: #f8f8f8;
$i-day-grey-8: #fcfcfc;

$i-divider-color: #ededed;

$i-day-primary-white: #ffffff;

$i-day-primary-green: #47d061;
$i-day-primary-green-darker: #2fb648; // $i-day-primary-green - 10% of lightness
$i-day-primary-green-darkest: #258e38; // $i-day-primary-green-darker - 20% of lightness
$i-day-primary-green-lighter: #71da84; // $i-day-primary-green + 10% of lightness
$i-day-primary-green-lightest: #c2efca; // $i-day-primary-green + 30% of lightness

$i-day-primary-red: #eb5757;
$i-day-primary-red-darker: #e62828; // $i-day-primary-red - 10% of lightness
$i-day-primary-red-darkest: #c41717; // $i-day-primary-red-darker - 10% of lightness

$i-day-primary-yellow: #ffa552;
$i-day-primary-yellow-darker: #ff8b1f; // $i-day-primary-yellow - 10% of lightness
$i-day-primary-yellow-darkest: #eb7100; // $i-day-primary-yellow-darker - 10% of lightness

$i-day-primary-blue: #51a3ee;

:root {
  --icabbi-accent-color: $i-day-primary-green;
  --icabbi-background-color: #dff6e0;
}
