@import './variables/colors.scss';
// This file has colors that are used in app

$primary-color: $i-day-primary-green;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ STATUSES COLORS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$pending-color: $i-day-grey-2;
$paid-color: $i-day-primary-green;
$declined-color: $i-day-primary-red;
$refunded-color: $i-day-primary-blue;
$disputed-color: $i-day-primary-yellow;

.mobile-row {
  padding: 10px;
  padding-bottom: 0;
}
