@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.groupedBooking {
  margin-bottom: 30px;
}

.groupedBookingItemWrapper {
  background-color: $i-day-primary-white;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 24px 32px;

  :global(.ant-row) {
    border-bottom: 1px solid $i-day-grey-5;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    cursor: pointer;
    &:global(:first-child) {
      padding-top: 0;
    }
    &:global(:last-child) {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    :global(.ant-col) {
      h2,
      h3 {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 400;
        line-height: 1.2;
      }
      h3 {
        color: $i-day-grey-2;
        margin-top: 10px;
      }
    }
  }
}

.groupedBookingHeader {
  @include mixin.flex(center, space-between);
  padding-right: 32px;
  & h1 {
    color: $i-day-grey-2;
    margin-bottom: 8px;
  }
}
