@import "../../styles/theme.scss";

.title {
  margin: 0 0 $space;
  color: $i-day-grey-0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.24px;
}
