@import "../../../styles/theme.scss";

.statusWrapper {
  @media (max-width: $breakpoint-sm) {
    border-top: 1px solid $i-day-grey-5;
    margin-top: $space-3;
  }
}

.statusTicket {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid $i-divider-color;
}

.statusTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.005em;
  color: $i-day-grey-0;

  @media (max-width: $breakpoint-sm) {
    font-size: 17px;
    line-height: 25px;
  }
}

.statusSubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: $i-day-grey-0;

  @media (max-width: $breakpoint-sm) {
    font-size: 13px;
    line-height: 20px;
  }
}

.statusContent {
  margin-left: 12px;
  flex: 1;
  & * {
    margin: 0;
  }
}

.editBtn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-decoration-line: underline;
  color: $i-day-primary-green;
  margin: 0;

  &:last-child {
    margin-left: 8px;
  }

  @media (max-width: $breakpoint-sm) {
    font-size: 15px;
    line-height: 21px;
  }
}
