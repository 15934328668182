@import "../../../styles/theme.scss";

$breakpoint: 800px;

.page {
  position: relative;

  min-height: 100svh;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: $i-day-primary-white;
  background-size: cover;
  background-position: center center;
}

.backdrop {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: $i-day-grey-0;
}

.content {
  position: relative; // to overlap the backdrop

  display: flex;
  flex-direction: column;
  gap: $space-5;

  padding: $space-3;

  @media (min-width: $breakpoint) {
    padding: 10vh 5vw;
  }
}

.title {
  margin-bottom: $space-3-alternative;

  color: inherit;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;

  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
}

.lead {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: $space-3;
  letter-spacing: -0.085px;
}

.columnList {
  display: flex;
  flex-direction: column;
  gap: $space-3-alternative;

  @media (min-width: $breakpoint) {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex: 1 1 0;
  gap: $space-2-alternative;

  @media (min-width: $breakpoint) {
    gap: $space-2;
    flex-direction: column;
  }
}

.columnIconWrapper {
  flex: 0 0 0;
  width: $space-4;
}

.columnTextWrapper {
  flex: 1 1 auto;
}

.columnTitle {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: $space-3;
  letter-spacing: -0.085px;

  text-wrap: balance; // if the browser does not support it, the browser skips it
}

.columnLead {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: $space-3;
  letter-spacing: -0.15px;

  text-wrap: balance; // if the browser does not support it, the browser skips it
}

.footer {
  display: flex;
  gap: $space-2;
  flex-direction: column;

  @media (min-width: $breakpoint) {
    flex-direction: row;
    align-items: baseline;
  }
}

.primaryLinkWrapper {
  @media (min-width: $breakpoint) {
    width: 200px;
  }
}

.primaryLink {
  display: block;

  text-align: center;

  color: $i-day-black;
  background: $i-day-primary-white;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.22px;

  padding: $space $space-3;
  border-radius: 28px;

  transition: all 0.2s;

  &:hover,
  &:focus {
    color: $i-day-black;
    background: $i-day-grey-4;
  }

  &:active {
    background: $i-day-grey-2;
  }

  @media (min-width: $breakpoint) {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: $space-3;
    letter-spacing: -0.075px;
  }
}

.secondaryText {
  text-align: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.secondaryLink {
  color: inherit;
  text-decoration: underline;
  background: transparent;
  border: 0;

  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.footerDocuments {
  margin-top: $space-2;
  text-align: center;

  @media (min-width: $breakpoint) {
    margin-top: $space;
    text-align: left;
  }
}
