@import "../../styles/theme.scss";

// $horizontal-padding: $space-2;
// $vertical-padding: $space-2-alternative;

// .datePicker {
//   border: 1px solid $i-day-grey-4 !important;
//   padding: $vertical-padding $horizontal-padding !important;
//   width: 100% !important;

//   font-size: 15px !important;
//   line-height: 21px !important;

//   color: $i-day-grey-0 !important;
//   background: $i-day-grey-8 !important;
//   border-radius: 6px !important;

//   & :global(.ant-picker-suffix) {
//     display: none;
//   }
// }

// .invalid {
//   border-color: $i-day-primary-red-darkest !important; /* it competes with antd styles */
//   outline-color: $i-day-primary-red-darkest;
// }

.customDatePicker {
  &:global(.ant-picker) {
    height: 44px;
    border-radius: 6px;
    width: 100%;
    &:hover,
    &:global(.ant-picker-focused) {
      border-color: $i-day-grey-0 !important;
    }
    &:global(.ant-picker-focused) {
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%);
    }
    & :global(.ant-picker-active-bar) {
      background: $i-day-grey-0;
    }
  }
}
.customDatePickerPannels {
  & :global(.ant-picker-header-view) {
    & :global(button:hover) {
      color: $i-day-primary-green;
    }
  }
  & :global(.ant-picker-footer) {
    & :global(a),
    & :global(button) {
      color: $i-day-primary-green;
    }
    & :global(a:hover),
    & :global(button:hover) {
      color: $i-day-primary-green-lighter;
    }
    &
      :global(.ant-picker-ranges)
      :global(.ant-picker-preset)
      > :global(.ant-tag-blue) {
      color: $i-day-primary-white !important;
      background-color: $i-day-primary-green !important;
      border-color: $i-day-primary-green-darkest !important;
    }
  }
  & :global(.ant-picker-cell-in-view) {
    &:global(.ant-picker-cell-today) :global(.ant-picker-cell-inner:before) {
      border-color: $i-day-primary-green !important;
    }

    &:global(.ant-picker-cell-range-start) :global(.ant-picker-cell-inner) {
      background: $i-day-primary-green !important;
    }

    &:global(.ant-picker-cell-range-end) :global(.ant-picker-cell-inner) {
      background: $i-day-primary-green !important;
    }

    &:global(.ant-picker-cell-in-range:before) {
      background: $i-day-primary-green-lightest !important;
    }

    &:global(
        .ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before
      ),
    &:global(
        .ant-picker-cell-range-start:not(
            .ant-picker-cell-range-start-single
          ):before
      ) {
      background: $i-day-primary-green-lightest !important;
    }

    &:global(.ant-picker-cell-range-hover-end):global(
        .ant-picker-cell-range-end-single:after
      ),
    &:global(.ant-picker-cell-range-hover-end):global(
        .ant-picker-cell-range-start
      ):global(.ant-picker-cell-range-end):global(
        .ant-picker-cell-range-start-near-hover:after
      ),
    &:global(
        .ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
            .ant-picker-cell-range-start
          ):not(.ant-picker-cell-range-end):after
      ),
    &:global(.ant-picker-cell-range-hover-start):global(
        .ant-picker-cell-range-start-single:after
      ),
    &:global(.ant-picker-cell-range-hover-start):global(
        .ant-picker-cell-range-start
      ):global(.ant-picker-cell-range-end):global(
        .ant-picker-cell-range-end-near-hover:after
      ),
    &:global(
        .ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
            .ant-picker-cell-range-start
          ):not(.ant-picker-cell-range-end):after
      ),
    &:global(
        .ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after
      ) {
      border-color: $i-day-primary-green !important;
    }
    &:global(.ant-picker-cell-range-end) :global(.ant-picker-cell-inner),
    &:global(.ant-picker-cell-range-start) :global(.ant-picker-cell-inner),
    &:global(.ant-picker-cell-selected) :global(.ant-picker-cell-inner) {
      background: $i-day-primary-green !important;
    }
    .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
      color: $i-day-primary-white !important;
      background-color: $i-day-primary-green !important;
      border-color: $i-day-primary-green-darkest !important;
    }
  }
}
