@import "../../styles/theme.scss";

.container {
  width: 100%;

  padding: $space-5;
  background: $i-day-primary-white;
  border-radius: $container-border-radius;

  @media (min-width: $breakpoint-sm) {
    box-shadow: $container-box-shadow;
  }

  &.widthMedium {
    @media (min-width: $breakpoint-sm) {
      max-width: $container-max-width;
    }
  }

  &.widthSmall {
    @media (min-width: $breakpoint-sm) {
      max-width: $container-max-width-small;
    }
  }
}
