@use "src/styles/mixins" as mixin;
@import "src/styles/theme.scss";
@import "../../styles/theme.scss";

.confirm-modal-container {
  .ant-modal-header {
    height: 45px;
    padding: 0;
    .ant-modal-title {
      @include mixin.text(16px, 600, 0, #323338);
    }
  }

  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-footer {
    .ant-btn {
      border-radius: 9999px;
    }
    .ant-btn-primary {
      background: $i-day-primary-green;
      border-color: $i-day-primary-green;
    }
    .ant-btn-primary:active,
    .ant-btn-primary:focus {
      border-color: $i-day-primary-green-darkest;
      background: $i-day-primary-green-darkest;
    }
    .ant-btn-primary:hover {
      border-color: $i-day-primary-green-darker;
      background: $i-day-primary-green-darker;
    }

    .ant-btn-default {
      color: $i-day-primary-green;
      background: $i-day-primary-white;
      border-color: $i-day-primary-green;
    }

    .ant-btn-default:hover {
      color: $i-day-primary-green-darker;
      background-color: $i-day-primary-white;
      border-color: $i-day-primary-green-darker;
    }
    .ant-btn-default:active,
    .ant-btn-default:focus {
      color: $i-day-primary-green-darkest;
      border-color: $i-day-primary-green-darkest;
    }
  }
}
