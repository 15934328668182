@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.rightDetails {
  padding: 0 15px;
  display: flex;
  flex-direction: column;

  .rightDetailsRow {
    border-bottom: 1px solid $i-day-grey-5;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
      border-bottom: none;
    }

    .rightDetailsBanner {
      @include mixin.flex(center, space-between);

      h4 {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  .bottomRow {
    .rightDetailsBanner {
      @include mixin.flex(center, space-between);
    }
  }

  svg {
    align-self: flex-end;
  }
}
