@import '../../styles/theme.scss';

.menuToggle {
  & circle {
    fill: $i-day-grey-6;
  }
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
}
