@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.driverBookings {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.driverBookingsWrapper {
  margin-top: 32px;
  margin-top: 16px;

  :global(.ant-col:first-child) {
    overflow: auto;
  }
}

.bookingsListInternal {
  height: 100%;
  min-height: calc(100vh - 345px);

  @media (max-width: 768px) {
    flex-grow: 1;
  }
}
