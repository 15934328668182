@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.wrapper {
  height: 44px;
  padding: 10px $space-2;
  display: flex;
  gap: $space-2-alternative;
  background: $i-day-primary-white;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  position: relative;
  & * {
    font-family: Inter, sans-serif;
  }
  min-width: 360px;
  &:hover {
    border-color: $i-day-grey-0 !important;
  }
  &:focus .wrapperOpen,
  &:active .wrapperOpen {
    border-color: $i-day-grey-0 !important;
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
  }
}

.wrapperOpen {
  border-radius: 6px;
  border-color: $i-day-grey-0 !important;
  box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
}

.options {
  position: absolute;
  top: 43px;
  left: 0;
  width: 100%;
  z-index: 100;
  @media (min-width: $breakpoint-md) {
    max-height: 400px;
    overflow: auto;
    border: 1px solid $i-day-grey-5;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  @media (max-width: $breakpoint-md) {
    position: relative;
    top: auto;
    left: auto;
  }
}

.optionName {
  padding: $space-3-alternative $space-2 $space-2-alternative;
  background: $i-day-grey-8;
  border: 1px solid $i-day-grey-5;
  border-left: none;
  border-right: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: $i-day-grey-2;

  @media (max-width: $breakpoint-md) {
    color: $i-day-grey-0;
    font-size: 15px;
    padding: 0;
    background-color: $i-day-primary-white;
    border: none;
    margin-left: $space-4;
    margin-bottom: $space-2;
  }
}

.divider {
  height: 1px;
  background-color: $i-day-grey-5;
  margin: $space-3 $space-4;
}

.dropdownHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  gap: $space-2-alternative;
}

.list {
  & > div {
    padding: 10.5px 20px;
    background-color: $i-day-primary-white;

    border-bottom: 0;
    display: flex;
    align-items: center;
    &:first-of-type {
      border-top: 0;
    }
    &:last-of-type {
      border-radius: 0 0 6px 6px;
    }
    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $i-day-grey-0;
      margin-left: $space-2;
    }
  }
  @media (max-width: $breakpoint-md) {
    & :global(.ant-checkbox-wrapper) {
      margin-left: $space !important;
    }
  }
}

.labelContainer {
  display: flex;
  flex-grow: 1;
}

.labelItem {
  flex-grow: 1;

  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;

  text-align: center;

  &.bold {
    font-weight: bolder;
  }
}

.labelSeparator {
  margin: 0 $space-alternative;
  width: 1px;
  background-color: $i-day-grey-5;
}
