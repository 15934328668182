@import "../../styles/theme.scss";

.background {
  @media (min-width: $breakpoint-sm) {
    min-height: 100vh;

    background-color: $i-day-grey-6;
    background-image: url("../../assets/icons/waveDivider.png");
    background-repeat: no-repeat;
    background-position: center 100%;
    background-size: contain;
  }
}

.aligner {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
    padding: $space-5;
  }
}
