@import "../../styles/theme.scss";

.description {
  color: $i-day-grey-0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.075px;

  p {
    margin-bottom: $space-3-alternative;
  }

  &.error {
    color: $i-day-primary-red;
  }
}
