.enterPasswordToContinueModal {
  & :global(.ant-modal-content) {
    border-radius: 12px;
  }

  & :global(.ant-input-password) {
    height: 44px;
    border-radius: 6px;
  }
}
