@import "../../../../styles/theme.scss";

.documentListItem {
  padding: $space-2 0;
  border-bottom: 1px solid $i-day-grey-5;

  .row {
    display: flex;
    align-items: center;
  }

  .content {
    margin-left: 12px;
    flex: 1;
    color: $i-day-grey-0;

    @media (max-width: $breakpoint-sm) {
      max-width: 180px;
      overflow: hidden;
    }
  }

  .badges {
    margin-left: 44px;
    margin-top: 6px;
  }

  .title {
    margin: 0;
    font-weight: $bold-weight;
    font-size: 15px;
    line-height: $space-3;

    @media (max-width: $breakpoint-sm) {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .text {
    margin: 0;
    font-size: 13px;
    line-height: $space-3-alternative;
  }

  .link {
    font-weight: $semi-bold-weight;
    font-size: 13px;
    line-height: 19px;
    color: $i-day-primary-green;
    text-decoration: underline;

    &:active,
    &:hover {
      color: $i-day-primary-green;
    }

    &:last-child {
      margin-left: $space;
    }

    @media (max-width: $breakpoint-sm) {
      font-size: 15px;
      line-height: 21px;
    }
  }
}
