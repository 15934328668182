@use "../../../styles/mixins.scss" as mixin;
@import "../../../styles/theme.scss";

.exportBtn {
  outline: 0;
  background-image: none;
  background: $i-day-primary-white;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: $i-day-black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
  padding: 4px 15px;
  text-align: center;
  touch-action: manipulation;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  white-space: nowrap;
  height: 44px;
  width: 44px;
  @include mixin.flex(center, center);

  &:hover,
  &:focus {
    color: $i-day-grey-0;
    border-color: $i-day-grey-0;
  }

  &:active {
    border-color: $i-day-grey-0;
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%);
    text-decoration: none;
  }
}

.content {
  font-size: 18px;
  @include mixin.flex(center, center);
}

.icon {
  transform: scale(2);
  stroke: rgb(51, 51, 51);
  &.disabled {
    stroke: $i-day-grey-3;
  }
}
