@import "../../styles/theme.scss";

.captions {
  padding-bottom: $space;
}

.field {
  flex-grow: 1;

  &:hover,
  &:focus {
    border-color: $i-day-grey-0 !important;
    outline: $i-day-grey-0 !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2) !important;
  }

  &.extraSpace {
    padding-bottom: $space-3-alternative;
  }
}

.description {
  margin-top: $space-alternative;
}
