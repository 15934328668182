.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row;
  gap: 16px;
}

.galleryItem {
  padding: 8px;
}

.galleryImageWrapper {
  display: block;
  width: 100%;
  text-align: center;
  height: 200px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.galleryImage {
  max-width: 100%;
  max-height: 100%;
}

.galleryCaption {
  color: #777;
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
}

.galleryModalImageWrapper {
  padding: 16px 8px;
  text-align: center;
}

.galleryModalImage {
  max-width: 100%;
  max-height: 720px;
}
