@import "~antd/dist/antd.min.css";
@import "./styles/theme.scss";

body,
html {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

*,
body {
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.App {
  width: 100%;
}

.root {
  background-color: #f0f2f5;
}

#root {
  min-height: 100vh;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row-reverse !important; // navigation tooltip should be always over the main content
}

.ant-pagination-item-active {
  border-color: $i-day-primary-green !important;
}
.ant-pagination-item-active a {
  color: $i-day-primary-green-darker !important;
}
.ant-pagination-item:hover a {
  color: $i-day-primary-green !important;
}
.ant-pagination-prev:hover .ant-pagination-item-link {
  color: $i-day-primary-green !important;
}
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $i-day-primary-green !important;
}
