.loadingIcon {
  width: 14px;
  height: 14px;

  will-change: transform;

  animation: rotating 1s linear infinite;
}

@keyframes rotating {
  100% {
    rotate: 1turn;
  }
}
