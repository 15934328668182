@use "src/styles/mixins" as mixin;
@import "../../../../../styles/theme.scss";

.payout-wrapper {
  display: grid;
  grid-template-columns: 55% 45%;
  border-radius: 6px;
  border: 1px solid $i-day-grey-5;
  width: 100%;
  height: 32px;
  & * {
    font-family: Inter, sans-serif;
  }
  span {
    padding: 0 12px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: $i-day-grey-0;
    &:first-child {
      background-color: $i-day-grey-7;
    }
    &:last-child {
      color: $i-day-grey-0;
      font-weight: 600;
      justify-content: flex-start;
    }
  }
}

.payout-wrapper.disabled {
  pointer-events: none;
  & span {
    color: $i-day-grey-4;
  }
}
