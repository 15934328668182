@use "src/styles/colors" as color;
@import "../../styles/theme.scss";

.passwordStrengthIndicator {
  .strengthMeter {
    display: block;
    width: 100%;
    height: 3px;
    color: $i-day-grey-2;
    background-color: $i-day-grey-4;
    margin-top: 5px;
    border-radius: 5px;
  }

  .strengthBar {
    height: 100%;
    transition: width 0.3s ease-in-out;
    position: relative;

    &-1,
    &-2,
    &-3,
    &-4 {
      &:after {
        content: "";
        display: block;
        position: relative;
        width: auto;
        height: 3px;
        top: 0;
        left: 0;
      }
    }
    &-0 {
      width: 0;
    }
    &-1 {
      background-color: $i-day-primary-red;
      width: 25%;
    }

    &-2 {
      background-color: $i-day-primary-yellow;
      width: 50%;
    }

    &-3 {
      background-color: #ffeb3b;
      width: 75%;
    }

    &-4 {
      background-color: $i-day-primary-green;
      width: 100%;
    }
  }
  .strengthLabel {
    font-size: 11px;
    width: 100%;
    text-align: right;
  }
}
