@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.booking-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  .sub-top-wrapper {
    padding: 0 32px 32px;
    margin-top: 32px;
  }
  .details-wrapper {
    width: 100%;
    overflow-y: auto;
  }
  .details-section {
    width: 70%;
    margin: auto;
    margin-top: 25px;
    align-self: center;
    .bookingId {
      color: $i-day-grey-0;
      margin: 0;
    }
    @media (max-width: 800px) {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
    .collapseMobile {
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        & > div {
          width: 100% !important;
          border-left: none !important;
          padding-left: 0 !important;
        }
      }
    }
    .row-details {
      width: 100%;
      display: flex;
      &:last-child {
        display: flex;
        .banner-wrapper:nth-child(2) {
          border-left: 1px solid $i-day-grey-5;
          border-right: 1px solid $i-day-grey-5;
        }
        .banner-wrapper:nth-child(3) {
          padding-left: 20px;
        }
      }
      .banner-wrapper {
        width: 50%;
        margin-top: 10px;
        border-bottom: 1px solid $i-day-grey-5;
        h4 {
          margin-bottom: 3px;
          color: $i-day-grey-2;
          font-size: 11px;
          line-height: 19px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
        }
        p {
          color: $i-day-grey-0;
          font-weight: 600;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.01em;
          margin-bottom: 20px;
          margin-right: 8px;
        }
        .documents {
          color: $i-day-primary-green;
          text-decoration: underline;
          cursor: pointer;
        }
        &:nth-child(2) {
          border-left: 1px solid $i-day-grey-5;
          padding: 0 0 10px 20px;
          display: flex;
          flex-direction: column;
          p {
            margin: 0;
          }
        }
        p {
          margin-bottom: 13px;
        }
      }
    }
    .payment-breakdown-section {
      padding: 20px 0;
      h4 {
        color: $i-day-grey-2;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
      }
      div {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 100px 100px;
        h5 {
          color: $i-day-grey-0;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.01em;
        }
        &:last-child {
          h5 {
            font-weight: 600;
          }
        }

        @media (max-width: 600px) {
          grid-template-columns: 1fr 60px 60px;
          gap: 8px;
          & h4:not(:first-child),
          h5:not(:first-child) {
            text-align: right;
          }
        }
      }
    }
  }
}
