@import "../../../styles/theme.scss";

.wrapper {
  padding: 16px;
  border: 1px solid $i-divider-color;
  border-radius: 6px;
  background-color: $i-day-primary-white;
}

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: $i-day-grey-0;
  margin: 0;

  & label {
    text-decoration: underline;
    color: $i-day-primary-green;
    cursor: pointer;
  }
}

.viewOnlyFile {
  background-color: $i-day-primary-white;
  padding-left: 12px;
}

.viewOnlyList {
  & :first-child {
    & p {
      padding-top: 12px;
    }
    border-radius: 6px 6px 0 0;
  }
  & :last-child {
    border-radius: 0 0 6px 6px;
    padding-bottom: 12px;
    & p {
      padding-bottom: 0;
    }
  }
}

.labelWithData {
  padding-bottom: 12px;
  border-bottom: 1px solid $i-divider-color;
  margin-bottom: 12px;
}

.hiddenInput {
  display: none;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  & svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    & path {
      stroke: #4192f5;
    }
  }

  &:not(:first-child) {
    margin-top: 12px;
  }

  & > span {
    & p {
      max-width: 130px;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (min-width: 540px) {
        max-width: 250px;
      }
    }
  }
}

.fileItemName {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $i-day-grey-0;
  margin: 0;
}

.fileItemActionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileItemSize {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $i-day-grey-2;
  margin: 0;
}

.fileItemRemoveBtn {
  font-style: normal;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin: 0;
  margin-left: 16px;
  text-decoration-line: underline;
  color: $i-day-primary-green;
}

.fileLink {
  color: $i-day-primary-green;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $i-day-primary-green-darker;
    text-decoration: underline;
  }

  &:active {
    color: $i-day-primary-green-darkest;
  }
}
