@import "../../styles/theme.scss";

.contactWrapper {
  margin-top: $space-4;
  padding: $space-3;
  background: $i-day-primary-white;
  border-radius: $space-2-alternative;
  font-family: Inter, sans-serif;
}

.contactLinks {
  @media (min-width: $breakpoint-sm) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
}

.bannerWrapper {
  flex-grow: 1;
  margin-top: $space-2-alternative;

  h3 {
    font-size: 12px;
    color: $i-day-grey-2;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    color: $i-day-grey-0;
    font-weight: 600;
  }
}

.faqPageGalleryWrapper {
  margin-top: 12px;
  padding-bottom: 12px;
}
