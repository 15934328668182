@import "../../../../styles/theme.scss";

.details {
  border: 1px solid $i-day-grey-4;
}

.noBorderBottom {
  border-bottom: none;
}

.roundedTop {
  border-radius: 10px 10px 0 0;
}

.roundedBottom {
  border-radius: 0 0 10px 10px;
}

.summary {
  padding: 8px;
  cursor: pointer;
}

.content {
  padding: 4px 16px 8px;

  ol {
    padding-left: 16px;
  }
}
