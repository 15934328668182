.signInLink {
  font-weight: 700;
  color: inherit;
  text-decoration: underline;

  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}
