@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.user-wrapper {
  .sub-top-wrapper {
    width: 100%;
    padding: 0 32px 32px;
    margin-top: 32px;
  }
  .edit-section {
    padding-top: 50px;
    height: calc(85vh - 67px);
    background-color: $i-day-grey-8;
    @include mixin.flex(center, flex-start, column);

    @media (max-width: 900px) {
      & > .ant-form {
        width: 100% !important;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      & > .ant-alert {
        width: calc(100% - 2rem) !important;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .ant-alert {
      width: 50%;
    }
    .ant-form {
      width: 50%;
      margin-top: 25px;
      .ant-form-item-label > label {
        color: $i-day-grey-1;
        text-transform: uppercase;
      }
      .ant-input {
        height: 44px;
        border-radius: 6px;
      }
      .ant-select-single {
        .ant-select-selector {
          height: 44px;
          @include mixin.flex(center, flex-start);
          border-radius: 6px;
          .ant-select-selection-item {
            height: 30px;
            @include mixin.flex(center, flex-start);
          }
        }
      }
      .ant-btn {
        @include mixin.flex(center, center);
        height: 50px;
        width: 100px;
        color: $i-day-primary-white;
        background-color: $i-day-primary-green;
        border-color: $i-day-primary-green;
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 6px;
      }
    }
  }
}
