@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../../../styles/theme.scss";

.reports-header {
  display: grid;
  grid-template-columns: 300px repeat(5, 1fr) 24px;
  padding: 12px 32px;
  background-color: $i-day-primary-white;
  border-bottom: 1px solid $i-day-grey-6;
  border-top: 1px solid $i-day-grey-6;
  min-width: 1100px;
  div {
    & p {
      color: $i-day-grey-2;
      font-weight: 400;
      font-family: "Inter";
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-align: center;
      margin: auto !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      overflow: hidden;
      white-space: pre-line;
    }
    &:first-child {
      & p {
        text-align: left;
        padding-left: 8px;
      }
    }
  }
  @media (max-width: 1280px) {
    padding: 12px 24px;
  }
}

.reports-table-wrapper {
  overflow: auto hidden;
  overflow-y: hidden;
  flex-grow: 1;
  height: 100%;

  .mobile-report-id {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    text-decoration-line: underline;
    color: $i-day-primary-green;
    margin: 0 !important;
  }

  .mobile-report-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -12px;
  }

  .empty-wrapper {
    @media (min-width: $breakpoint-sm) {
      min-height: calc(85vh - 155px);
    }
    @media (max-width: $breakpoint-sm) {
      margin-top: 16px;
    }
  }

  .header {
    display: grid;
    grid-template-columns: 300px repeat(5, 1fr) 24px;
    padding: 12px 0;
    background-color: $i-day-primary-white;
    border-bottom: 1px solid $i-day-grey-6;
    border-top: 1px solid $i-day-grey-6;
    div {
      & p {
        color: $i-day-grey-2;
        font-weight: 400;
        font-family: "Inter";
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.08em;
        text-align: center;
        margin: auto !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        overflow: hidden;
        white-space: pre-line;
      }
    }

    @media (min-width: $breakpoint-sm) {
      padding-left: 2px;
    }
  }

  .reports-list-table-wrapper {
    overflow: auto hidden;

    .mobile-row {
      background-color: #f0f2f5;
      padding: 13px 0 0;
      & div {
        background: $i-day-primary-white;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $i-day-primary-green;
        border-color: $i-day-primary-green;
      }
    }
  }

  .reports-list-table-section-wrapper {
    @media (min-width: $breakpoint-sm) {
      min-width: 1100px;
      padding-bottom: 10px;
      min-height: calc(100vh - 320px);
      background-color: $i-day-grey-8;
    }
    @media (max-width: $breakpoint-sm) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      .report_id {
        color: $i-day-primary-green !important;
        text-decoration: underline;
        cursor: pointer;
        justify-content: flex-start !important;
        padding-left: 8px !important;
      }
      .row {
        display: grid;
        grid-template-columns: 300px repeat(5, 1fr) 24px;
        border-bottom: 1px solid $i-day-grey-5;
        &:last-child {
          border-bottom: none !important;
        }
        background-color: $i-day-grey-8;
        padding: 0 32px;
        @media (max-width: 1280px) {
          padding: 0 24px;
        }
      }
      .row.selected {
        background-color: $i-day-primary-white;

        & .cell {
          font-weight: 600;
        }
      }
      .cell {
        padding: 13px 0;
        color: $i-day-grey-0;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.01em;
        display: flex;
        align-self: center;
        justify-content: center;
        text-align: center;
        &:first-child {
          .ant-checkbox-wrapper {
            margin-right: 10px;
            .ant-checkbox-input:focus + .ant-checkbox-inner,
            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner {
              border-color: color.$primary-color;
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: color.$primary-color;
                border-color: color.$primary-color;
              }
            }
          }
        }
      }
      .cell.dropdown {
        & > div {
          margin-right: auto;
        }
      }
      .status-tag {
        @include mixin.flex(center, center);
        background-color: $i-day-grey-8;
        width: 100%;

        div {
          width: 102px;
          background-color: $i-day-primary-white;
          height: 24px;
          padding: 0 7px;
          border-radius: 6px;
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.08em;
        }

        @media (max-width: $breakpoint-sm) {
          padding: 0;
          margin-right: auto;
          width: fit-content;
        }
      }
      p {
        margin: 1em 0;
      }
    }
  }
}
.reports-table-container {
  height: 100%;
}
.reports-table-loading {
  @media (min-width: $breakpoint-sm) {
    & .reports-list-table-section-wrapper {
      min-height: calc(100vh - 225px);
    }
  }
}

.mobile-report-action-drawer {
  width: 100%;
  position: relative;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    font-family: Inter, sans-serif;
  }
  & h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: $i-day-grey-0;
    margin: 0;
    width: 100%;
    margin-bottom: 32px;
  }
  & p {
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 25px !important;
    letter-spacing: -0.005em !important;
    color: $i-day-grey-0;
    margin: 0;
    padding-left: 0 !important;
  }

  &__button-row--spaced {
    margin-top: 20px;
  }
}
