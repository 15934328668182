@import "../../styles/theme.scss";

.widgetContainer {
  padding: $space-4;
  background-color: $i-day-primary-white;
  border-radius: $space;
}

.widgetTitle {
  padding-bottom: $space;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid $i-day-grey-5;
}

.widgetStats {
  font-weight: 500;
}

.large {
  font-size: 26px;
}

.widgetSmall {
  color: $i-day-grey-1;
}

.widgetLink {
  color: $i-day-primary-green;
  font-weight: 500;

  &:hover,
  &:focus {
    color: $i-day-primary-green-darker;
  }

  &:focus-visible {
    outline: 1px solid $i-day-primary-green-darker;
    outline-offset: 4px;
    border-radius: 6px;
  }

  &:active {
    color: $i-day-primary-green-darkest;
  }
}

.underlined {
  border-bottom: 1px solid $i-day-primary-green;

  &:hover,
  &:focus {
    color: $i-day-primary-green-darker;
    border-bottom-color: transparent;
  }

  &:active {
    border-bottom-color: transparent;
  }
}

.center {
  display: flex;
  justify-content: center;
}
