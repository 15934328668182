@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.bookings-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .capitalize {
    text-transform: capitalize;
  }

  @media (max-width: $breakpoint-sm) {
    background: $i-day-grey-6;
  }

  .actions-header-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-sm) {
      margin-bottom: $space-5;
    }

    @media (max-width: 1280px) {
      padding: 32px 24px 0;
    }

    @media (max-width: 1500px) {
      justify-content: flex-start;
    }

    padding: 40px 32px 0;

    .filters {
      @include mixin.flex(center, center);
      gap: 12px;

      @media (max-width: $breakpoint-sm) {
        padding-top: 90px !important;
        background-color: #f0f2f5;
        padding-right: 16px;
        padding-left: 16px;
      }

      @media (max-width: 1500px) {
        margin-top: $space-2;
      }

      .ant-input-affix-wrapper {
        height: 44px;
        border-radius: 6px;
        background: $i-day-grey-8;
        & input {
          background: $i-day-grey-8;
        }
        @media (min-width: 1120px) {
          max-width: 250px;
        }

        @media (max-width: $breakpoint-sm) {
          height: 56px;
        }
        border-color: $i-day-grey-5;
        border: 1px solid $i-day-grey-5;
        border-left: none;
        box-shadow: none;
      }

      .filter-dropdown-wrapper > button {
        height: 44px;
        border-radius: 6px;
      }

      .ant-btn {
        box-shadow: none;
      }

      .ant-input-group-wrapper {
        width: 250px;
        border-radius: 6px;
        height: 44px;
        @media (max-width: $breakpoint-sm) {
          height: 56px;
        }

        .ant-input-group-addon {
          border: 1px solid $i-day-grey-5;
          border-right: none;
          padding-right: 0;
          background-color: $i-day-grey-8;
          & span svg {
            width: 16px;
            height: 16px;
            margin-top: 4px;
            fill: $i-day-grey-3;

            @media (max-width: $breakpoint-sm) {
              width: 20px;
              height: 20px;
            }
          }
        }
        .ant-input-wrapper {
          height: 44px;
          @media (max-width: $breakpoint-sm) {
            height: 56px;
          }
          border-radius: 6px;
          border-color: $i-day-grey-5;
        }
        .ant-input,
        .ant-select-selector {
          height: 44px;
          @include mixin.flex(center, center);
          @media (max-width: $breakpoint-sm) {
            height: 56px;
          }
        }

        @media (max-width: 1120px) {
          width: 100%;
        }
      }
      .ant-picker {
        width: 260px;
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        .ant-picker-active-bar {
          display: none;
        }

        @media (max-width: 1120px) {
          width: 100%;
        }

        @media (max-width: $breakpoint-sm) {
          height: 56px;
        }
      }
      .ant-picker .ant-picker-input span.ant-picker-suffix,
      .ant-picker.ant-picker-range span.ant-picker-suffix {
        margin-left: 1px;
        margin-right: 10px;
        order: -1;
      }
      .ant-input-wrapper.ant-input-group {
        & .ant-input-group-addon {
          border-radius: 6px 0 0 6px;
        }

        & .ant-input-affix-wrapper {
          border-radius: 0 6px 6px 0;
        }

        .ant-input {
          height: 34px;
          @media (max-width: $breakpoint-sm) {
            height: 46px;
          }
        }
      }

      .filter-dropdown-wrapper > button {
        & > svg:first-child {
          & path {
            stroke: $i-day-grey-2;
          }
          margin-right: 8px;
        }
        @media (max-width: $breakpoint-sm) {
          height: 56px;
          border-radius: 6px;
        }
      }

      .ant-picker-range {
        & .ant-picker-suffix {
          & path {
            stroke: $i-day-grey-2;
          }
        }
      }

      @media (max-width: 1280px) and (min-width: 1120px) {
        .filter-dropdown-wrapper > button {
          width: 100%;
          & > svg:first-child {
            display: none;
          }
        }

        .ant-picker-range {
          & .ant-picker-suffix {
            display: none;
          }
        }
      }

      @media (max-width: 1120px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
        width: 100%;

        .filter-dropdown-wrapper > button {
          width: 100%;
        }

        .payment-type {
          grid-column-end: auto;
        }

        .custom-dropdown-wrapper {
          grid-column-start: 2;

          @media (max-width: 630px) {
            grid-column-start: auto;
          }
        }
      }

      @media (max-width: 630px) {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: 1120px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & > .filters {
        grid-column: 1 / -1;
        margin-top: 10px;
      }

      padding-top: 24px;

      & > .custom-dropdown-wrapper {
        margin-left: auto;
      }
    }

    @media (max-width: $breakpoint-sm) {
      grid-template-columns: 64px 1fr 1fr;
      padding: 0;
    }
  }
  .bookings-table-wrapper {
    overflow: auto hidden;
    overflow-y: hidden;
    flex-grow: 1;

    .empty-wrapper {
      @media (min-width: $breakpoint-sm) {
        min-height: calc(85vh - 155px);
      }
      @media (max-width: $breakpoint-sm) {
        margin-top: 16px;
      }
    }

    .header {
      display: grid;
      grid-template-columns: 160px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      padding: 12px 0;
      background-color: $i-day-primary-white;
      border-bottom: 1px solid $i-day-grey-6;
      border-top: 1px solid $i-day-grey-6;
      div {
        & p {
          color: $i-day-grey-2;
          font-weight: 400;
          font-family: "Inter";
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.08em;
          text-align: center;
          margin: auto !important;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          overflow: hidden;
          white-space: pre-line;
        }
      }

      @media (min-width: $breakpoint-sm) {
        padding-left: 2px;
      }
    }

    .booking-list-table-wrapper {
      overflow: auto hidden;

      .mobile-row {
        background-color: #f0f2f5;
        padding: 13px 0 0;
        & div {
          background: $i-day-primary-white;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $i-day-primary-green;
          border-color: $i-day-primary-green;
        }
      }
    }

    .booking-list-table-section-wrapper {
      @media (min-width: $breakpoint-sm) {
        min-width: 1300px;
        padding-bottom: 10px;
        min-height: calc(100vh - 320px);
        background-color: $i-day-grey-8;
      }

      @media (max-width: $breakpoint-sm) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .infinite-scroll-component__outerdiv {
      .infinite-scroll-component {
        .booking_id {
          color: $i-day-primary-green !important;
          text-decoration: underline;
          cursor: pointer;
        }
        .row {
          display: grid;
          grid-template-columns: 160px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          border-bottom: 1px solid $i-day-grey-5;
          &:last-child {
            border-bottom: none !important;
          }
          background-color: $i-day-grey-8;
        }
        .row.selected {
          background-color: $i-day-primary-white;

          & .cell {
            font-weight: 600;
          }
        }
        .cell {
          padding: 13px 0;
          color: $i-day-grey-0;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.01em;
          display: flex;
          align-self: center;
          justify-content: center;
          text-align: center;
          &:first-child {
            .ant-checkbox-wrapper {
              margin-right: 10px;
              .ant-checkbox-input:focus + .ant-checkbox-inner,
              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner {
                border-color: color.$primary-color;
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: color.$primary-color;
                  border-color: color.$primary-color;
                }
              }
            }
          }
        }
        .payment-type {
          text-align: center;
        }
        .status-tag {
          @include mixin.flex(center, center);
          background-color: $i-day-grey-8;
          width: auto;

          div {
            width: 102px;
            background-color: $i-day-primary-white;
            height: 24px;
            padding: 0 7px;
            border-radius: 6px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.08em;
          }

          @media (max-width: $breakpoint-sm) {
            padding: 0;
            margin-right: auto;
            width: fit-content;
          }
        }

        .status-tag.cell {
          height: 48px;
        }
        p {
          margin: 1em 0;
        }
      }
    }
  }
  .bookings-footer {
    width: 100%;
    height: 48px;
    @include mixin.flex(center, space-between);
    padding: 0 32px;
    border-top: 1px solid $i-day-grey-6;
    span {
      @include mixin.flex(center, center);
      & svg {
        margin-right: 10px;
      }
      h3 {
        font-size: 14px;
        margin: 0;
        color: $i-day-grey-2;
      }
    }
    @media (max-width: $breakpoint-sm) {
      background-color: $i-day-primary-white;
    }
  }
  .scrollTop {
    color: $i-day-grey-2;
    cursor: pointer;
    text-decoration: underline;
    font-family: Inter, Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .bookings-sticky-header {
    display: grid;
    grid-template-columns: 64px 1fr 1fr;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 16px;
    z-index: 1;
    background-color: $i-day-primary-white;
    border-bottom: 1.5px solid rgba(51, 51, 51, 0.08);

    & > .custom-dropdown-wrapper {
      margin-left: auto;
    }
  }
}
