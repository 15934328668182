@import "../../../../styles/theme.scss";

.wrapper {
  padding: 24px 32px;
  background-color: $i-day-primary-white;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 24px;

  @media (max-width: $breakpoint-sm) {
    padding: 16px;
  }
}

.date {
  color: $i-day-grey-2;
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 16px;
  }
}

.divider {
  background-color: $i-day-grey-5;
  height: 1px;
  width: 100%;
  margin: 1rem 0;
}
