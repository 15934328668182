@import "../../styles/theme.scss";

.abstractInput {
  &:global(.ant-input) {
    width: 100%;
    border: 1px solid $i-day-grey-4;
    border-radius: $input-border-radius;
    padding: $space-2-alternative $space-2;

    color: $i-day-grey-0;
    font-size: 15px;
    line-height: 21px;

    background: $i-day-grey-8;

    &:global(:hover),
    &:global(:focus) {
      border-color: $i-day-grey-0;
      outline: $i-day-grey-0;
    }
    &:global(:focus) {
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2);
    }
  }
}

.invalid {
  border-color: $i-day-primary-red-darkest;
  outline-color: $i-day-primary-red-darkest;
}

.readonly {
  color: $i-day-grey-1;
}
