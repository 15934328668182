@import "~styles/theme.scss";

.dashboard-picker {
  min-width: 185px;
  display: flex;
  flex-direction: column;

  &__input {
    .ant-select-selector {
      height: 44px !important;
      border-radius: 6px !important;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      font-size: 15px;
    }
  }
}
