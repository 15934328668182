.stripeAlertBanner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;

  & :global(.ant-tag) {
    margin-right: 0;
  }

  & :global(svg) {
    margin-top: 12px;

    @media (max-width: 894px) {
      display: none;
    }
  }
}
