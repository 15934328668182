$letter-spacing: 0.08em;
$text-transform: uppercase;

$input-border-radius: 6px;
$container-border-radius: 12px;

$container-box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
$tooltip-box-shadow: rgba(17, 12, 46, 0.15) 0 8px 18px 0;
$container-max-width-small: 478px;
$container-max-width: 680px;

$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

@mixin sizes($font-size, $font-weight: 400, $line-height, $font-style: normal) {
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin fontSize($font-size) {
  font-size: $font-size;
}

@mixin fontWeight($font-weight) {
  font-weight: $font-weight;
}

@mixin lineHeight($line-height) {
  line-height: $line-height;
}

@mixin fontStyle($font-style) {
  font-style: $font-style;
}
