@import "../../../styles/theme.scss";

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $i-day-grey-6;
  background-image: url("../../../assets/icons/waveDivider.png");
  background-repeat: no-repeat;
  background-position: bottom;

  @media (max-width: $breakpoint-sm) {
    & div {
      max-width: 100%;
      border-radius: 0;
    }
    background-image: none;
    background-color: $i-day-primary-white;
  }
}

.container {
  & button {
    height: 48px;
  }
}

.heading {
  color: $i-day-grey-0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  margin: 0;
  margin-bottom: 8px;

  @media (max-width: $breakpoint-sm) {
    font-size: 26px;
  }
}

.subheading {
  font-size: 13px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: -0.01em;
  margin: 0;
}

.divider {
  width: 100%;
  background-color: $i-divider-color;
  height: 1px;
  border: none;
  margin: 0;
}

.stripeIcon {
  margin-top: $space-4;
}
