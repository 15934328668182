@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.driverBookingsFilters {
  width: 100%;
  margin-top: -10px;
  padding: 25px 30px 15px;
  background-color: $i-day-grey-7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  gap: 10px;

  :global(.ant-picker) {
    min-width: 350px;
    border-radius: 6px;
    @media (max-width: 1180px) and (min-width: 769px) {
      width: 100%;
      min-width: auto;
    }
  }

  @media (max-width: 1180px) and (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: grid;
    padding: 0;
    flex-wrap: wrap;
    background: transparent;
    margin-top: 24px;
    grid-template-columns: 1fr;
  }
}

.doubleDropdown {
  @media (max-width: 1180px) {
    min-width: auto;
  }
}

.driverFilterMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-column: 1 / -1;
  gap: 12px;
}
