@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.driver-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $i-day-grey-8;
  .sub-top-wrapper {
    padding: 0 32px 32px;
    margin-top: 32px;
    background-color: $i-day-primary-white;
  }
  .content {
    @include mixin.flex(center, flex-start, column);

    .collapseMobile {
      @media (max-width: 400px) {
        flex-direction: column;
        & > div {
          width: 100% !important;
          border-left: none !important;
          padding-left: 0 !important;
        }
      }
    }

    overflow-y: auto;
    background-color: $i-day-grey-8;
    .identity-section,
    .payment-section,
    .performance-section {
      width: 70%;
      margin-top: 25px;
      align-self: center;
      // border-bottom: 1px solid $i-day-grey-5;
      margin-bottom: 20px;

      @media (max-width: 800px) {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
      .row-details {
        width: 100%;
        display: flex;
        // &:last-child {
        //   display: flex;
        //   .banner-wrapper:nth-child(2) {
        //     border-left: 1px solid $i-day-grey-5;
        //     border-right: 1px solid $i-day-grey-5;
        //   }
        //   .banner-wrapper:nth-child(3) {
        //     padding-left: 20px;
        //   }
        // }
        .banner-wrapper {
          // width: 50%;
          width: 100%;
          margin-top: 10px;
          border-bottom: 1px solid $i-day-grey-5;
          h4 {
            margin-bottom: 3px;
            color: $i-day-grey-2;
            font-size: 11px;
            line-height: 19px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }
          p {
            color: $i-day-grey-0;
            font-weight: 600;
            font-size: 13px;
            line-height: 21px;
            letter-spacing: -0.01em;
            margin-bottom: 20px;
          }
          .documents {
            color: $i-day-primary-green;
            text-decoration: underline;
            cursor: pointer;
          }
          &:nth-child(2) {
            border-left: 1px solid $i-day-grey-5;
            padding: 0 0 10px 20px;
            display: flex;
            flex-direction: column;
            p {
              margin: 0;
            }
          }
          p {
            margin-bottom: 13px;
          }
        }
      }
      .payment-view-btn {
        margin-top: 10px;
        color: $i-day-primary-green;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .payment-section {
      border-bottom: none;
      margin-bottom: 0;
      margin-top: 0;
    }
    .feedback-section {
      margin-top: 25px;
      width: 70%;
      .tabs-wrapper {
        @include mixin.flex(center, flex-start);
        span {
          display: flex;
          .btn {
            padding: 4px 10px;
            margin-right: 10px;
            border: 1px solid $i-day-primary-green;
            color: $i-day-primary-green;
            border-radius: 6px;
            cursor: pointer;
            &:hover {
              background-color: $i-day-grey-0;
              color: $i-day-primary-white;
              border-color: $i-day-grey-0;
            }
          }
        }
        h4 {
          margin: 0 0 0 10px;
          font-weight: normal;
        }
      }

      .comment-wrapper {
        padding: 10px 0 0;
        border-top: 1px solid $i-day-grey-5;
        margin: 15px 0 20px;
        font-size: 13px;
        span {
          font-weight: 600;
          h4 {
            margin-bottom: 0;
          }
          .ant-rate {
            .ant-rate-star {
              color: $i-day-primary-green;
              margin-right: 10px;
            }
          }
        }
        p {
          margin-top: 15px;
        }
      }
    }
  }
}
