@import "../../styles/theme.scss";

.navLink {
  position: relative;

  display: flex;
  align-items: center;

  padding-top: $space;
  padding-bottom: $space;

  color: inherit;
  border-left: 3px solid transparent;
  transition: all 0.1s;

  &:hover {
    color: $i-day-primary-green-darker;
    background-color: $i-day-grey-7;

    .navLinkIcon {
      color: $i-day-primary-green-darkest;
    }
  }

  &.active {
    font-weight: 700;
    border-left-color: $i-day-primary-green-darker;

    .navLinkIcon {
      color: $i-day-primary-green-darkest;
    }

    &.warning {
      .navLinkIcon {
        color: $i-day-primary-yellow-darker;
      }
    }

    &.error {
      .navLinkIcon {
        color: $i-day-primary-red-darker;
      }
    }
  }

  &.warning {
    &:hover {
      color: $i-day-primary-yellow-darker;

      .navLinkIcon {
        color: $i-day-primary-yellow-darker;
      }
    }

    &.active {
      border-left-color: $i-day-primary-yellow-darker;
    }
  }

  &.error {
    &:hover {
      color: $i-day-primary-red-darker;

      .navLinkIcon {
        color: $i-day-primary-red-darker;
      }
    }

    &.active {
      border-left-color: $i-day-primary-red-darker;
    }
  }

  &.collapsed:hover {
    .navLinkText {
      opacity: 1;
      visibility: visible;
    }
  }
}

.navLinkText {
  padding-left: $space;
  transition: all 0.1s;

  line-height: 24px;

  &.collapsed {
    position: absolute;
    left: 100%;
    transition: opacity 0.1s;
    color: $i-day-black;

    padding-left: $space-2;

    opacity: 0;
    white-space: nowrap;
    visibility: hidden;

    .navLinkTextInner {
      padding: $space;
      background-color: $i-day-grey-6;
      border-radius: $space;
      box-shadow: $tooltip-box-shadow;
    }
  }
}

.navLinkIcon {
  display: flex;
  margin-left: $space-2;
  color: $i-day-grey-2;
  transition: all 0.1s;
}

.navLinkBadge {
  position: absolute;

  left: 36px;
  top: 0;

  width: 12px;
  height: 12px;
}

.navLinkBadgeText {
  width: 12px;
  height: 12px;

  color: $i-day-primary-white;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;

  background-color: $i-day-primary-yellow-darker;
  border-radius: 50%;

  &.small {
    font-size: 7px;
  }
}
