@import "../../styles/theme.scss";

.numberInput {
  &.ant-input-number {
    display: flex;
    margin-bottom: 12px;
    border-right-width: 1px;
    outline: 0;
    width: 130px;
    align-items: center;
    border-radius: 6px;
    height: 44px;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: $i-day-grey-8;

    .ant-input-number-input {
      background-color: $i-day-grey-8;
    }
  }
  &.ant-input-number-focused,
  &.ant-input-number:hover {
    border-color: $i-day-grey-0 !important;
  }

  &.ant-input-number-focused {
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
  }

  & .ant-input-number-handler-wrap {
    border-radius: 0 6px 6px 0;
  }

  & .ant-input-number-handler {
    &:hover {
      .ant-input-number-handler-down-inner,
      .ant-input-number-handler-up-inner {
        color: $i-day-grey-0 !important;
      }
    }
  }
}

// .invalid {
//   border-color: $i-day-primary-red-darkest;
//   outline-color: $i-day-primary-red-darkest;
// }

// .readonly {
//   color: $i-day-grey-1;
// }
