@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.company-selector {
  width: 100%;
  height: auto;
  border-bottom: 1px solid $i-day-grey-5;
  .menu-trigger {
    padding: 0 16px 0px 12px;
    @include mixin.flex(center, space-between);
    height: 100%;
    cursor: pointer;
    .top-icon {
      @include mixin.flex(center, space-between);
      & svg {
        min-width: 32px;
        @media (max-width: $breakpoint-sm) {
          min-width: 56px;
          height: 56px;
          width: 56px;
        }
      }
      h3 {
        margin: 0;
        margin-left: $space;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.005em;
        min-width: 120px;
        transition: all 0.3s;
        color: $i-day-grey-0;
        @media (max-width: $breakpoint-sm) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    & > svg {
      @media (max-width: $breakpoint-sm) {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.companies-selector-mobile-view-drawer {
  .ant-drawer-content {
    background-color: transparent;
    .ant-drawer-header {
      border-bottom: none;
    }
    .ant-drawer-wrapper-body {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: $i-day-primary-white;
      .ant-radio-group {
        max-height: 130px;
        overflow: auto;
        .ant-radio-wrapper {
          margin: 5px 0;
          & .ant-radio-checked .ant-radio-inner,
          & .ant-radio:hover .ant-radio-inner {
            border-color: color.$primary-color;
          }
          & .ant-radio-inner:after {
            background-color: color.$primary-color;
          }
          & .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
            border-color: color.$primary-color;
          }
        }
      }
      .ant-btn {
        margin-top: 15px;
      }
    }
  }
  z-index: 10000 !important;
}

.company-selector-mobile {
  background: $i-day-primary-white;
  border-radius: 24px 24px 0 0;
  position: relative;
  max-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;

  & > .company-selector-mobile-list {
    overflow-y: auto;
    height: 100%;
  }

  &__header {
    margin-top: 8px;
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $i-day-grey-0;
  }

  .close-btn {
    flex-shrink: 0;
    & svg {
      width: 20px;
      height: 20px;
    }
  }

  .company-selector-mobile-list {
    & div {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      & p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: $i-day-grey-0;
        margin: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }
    margin-bottom: 40px;
  }

  .ant-radio-wrapper {
    cursor: pointer;
    margin: 0;
    align-items: center;

    .ant-radio {
      top: 0;
    }
  }

  & .ant-radio-checked .ant-radio-inner,
  & .ant-radio:hover .ant-radio-inner {
    border-color: color.$primary-color;
  }
  & .ant-radio-inner:after {
    background-color: color.$primary-color;
  }
  & .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
    border-color: color.$primary-color;
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }
}

.company-selector-mobile-overflow {
  .company-selector-mobile__header {
    margin-top: 0;
    margin-bottom: 12px;
  }
  .ant-drawer-wrapper-body {
    height: 100vh;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .close-btn {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: $i-day-grey-7;
    border: 1px solid $i-day-grey-6;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.company-selector-desktop {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 9999;
  & * {
    font-family: "Inter", sans-serif;
  }

  .company-selector-desktop-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    & .ant-btn-default {
      color: $i-day-primary-white !important;
      background: $i-day-primary-green !important;
      border-color: $i-day-primary-green !important;
    }
    & .ant-btn-default:hover {
      background: $i-day-primary-green-darker !important;
      border-color: $i-day-primary-green-darker !important;
    }
    & .ant-btn-default:active,
    & .ant-btn-default:focus {
      background: $i-day-primary-green-darkest !important;
      border-color: $i-day-primary-green-darkest !important;
    }
  }

  .menu-wrapper {
    height: 64px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $i-day-grey-5;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $i-day-grey-0;
      margin: 0;
    }
    & svg {
      cursor: pointer;
    }
  }

  .ant-input-search {
    padding: 0 24px;
    margin-top: 24px;
    height: 32px;
    .ant-input-wrapper {
      .ant-input-group-addon:last-of-type {
        display: none;
      }
      .ant-input-group-addon {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 4px;
        padding-left: 10px;
        border: 1px solid $i-day-grey-5;
        border-right-width: 0;
        & svg {
          color: $i-day-grey-4;
        }
      }
      .ant-input {
        height: 32px;
        background-color: $i-day-grey-7;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 1px solid $i-day-grey-5;
        border-left-width: 0;
        padding-left: 4px;
        &:focus {
          border-color: $i-day-grey-5;
          box-shadow: none;
        }
      }
      .ant-input-search-button {
        background-color: $i-day-grey-7;
        border-left: none;
        height: 32px;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 1px solid $i-day-grey-5;
        border-left: none;
      }
    }
  }

  .company-searchbar-wrapper {
    display: flex;
    padding-top: 20px;
    width: calc(100% - 48px);
    align-items: center;
    justify-content: center;
  }
  .drawer-company-list-wrapper {
    width: 100%;
    padding: 24px;
    flex: 1;
    & div {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 14px;
      & p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.01em;
        color: $i-day-grey-0;
        margin: 0;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }

    .ant-radio-wrapper {
      cursor: pointer;
      margin: 0;
      align-items: center;

      .ant-radio {
        top: 0;
      }
    }

    & .ant-radio-checked .ant-radio-inner,
    & .ant-radio:hover .ant-radio-inner {
      border-color: color.$primary-color;
    }
    & .ant-radio-inner:after {
      background-color: color.$primary-color;
    }
    & .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
      border-color: color.$primary-color;
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
    }
  }
}

.sider-backdrop {
  min-height: 100vh;
  height: 100%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: $i-day-grey-0;
  opacity: 0.25;
}
