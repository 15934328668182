@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.subTopWrapper {
  @include mixin.flex(center, flex-start);
  border-bottom: 1px solid $i-day-grey-5;
  height: 90px;

  :global(.subTopWrapper__link) {
    line-height: 1;
  }

  :global(.subTopWrapper__header) {
    margin: 0 0 0 10px;
    color: $i-day-grey-0;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.02em;
  }
}
