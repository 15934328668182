@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.empty-wrapper {
  width: 100%;
  height: 100%;
  @include mixin.flex(center, center, column);
  .empty-description {
    margin-top: 20px;
    text-align: center;
    h1 {
      color: $i-day-grey-0;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-size: 13px;
    }
  }
}
