@import "../../../styles/theme.scss";

.custom-vehicle-replacement-promp.ant-modal {
  padding: 0;

  & .ant-modal-content {
    border-radius: 8px;
  }

  & .ant-btn {
    border-radius: 9999px;
  }
  .ant-btn-primary {
    background: $i-day-primary-green;
    border-color: $i-day-primary-green;
  }
  .ant-btn-primary:focus,
  .ant-btn-primary:active {
    border-color: $i-day-primary-green-darkest;
    background: $i-day-primary-green-darkest;
  }
  .ant-btn-primary:hover {
    border-color: $i-day-primary-green-lighter;
    background: $i-day-primary-green-lighter;
  }
  .ant-btn-default:hover {
    color: $i-day-primary-green;
    border-color: $i-day-primary-green;
  }
  .ant-btn-default:active,
  .ant-btn-default:focus {
    color: $i-day-primary-green-darker;
    border-color: $i-day-primary-green-darker;
  }
}

.button-group {
  display: flex;
  margin-top: 20px;
  justify-content: end;
  button {
    margin-left: 16px;
  }
}

.custom-add-new-vehicle {
  margin: 20px auto 0;
  width: 100%;
  max-width: 250px;
  justify-content: center;
}

.custom-replace-vehicle-registration-modal.ant-modal {
  & .ant-modal-content {
    border-radius: 8px;
  }
}

.custom-replace-registration-number-label {
  padding: 20px 0 10px;
}

.custom-replace-registration-number-input {
  border: 1px solid $i-day-grey-4;
  border-radius: 6px !important;
  padding: 12px 16px !important;

  &:hover,
  &:focus {
    border-color: $i-day-grey-0 !important;
    outline: $i-day-grey-0 !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2) !important;
  }
}
