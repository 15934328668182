@import "../../styles/theme.scss";

.pageHeader {
  padding: 0;
  margin-right: auto;
  display: flex;
  align-items: center;
  background-color: $i-day-primary-white;

  &__icon {
    margin-right: $space-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $space-6;
    height: $space-6;
    border: 1px solid $i-day-grey-5;
    border-radius: 50%;
  }

  &__title {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: -0.02em;
    color: $i-day-black;
  }
}
