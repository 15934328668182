@import "../../../styles/theme.scss";

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: $space;

  &.spaceBetween {
    justify-content: space-between;
  }
}

.item {
  display: flex;
  align-items: center;
}

.delimiter {
  height: $space-3;
  width: $space-3;
  transform: rotate(-90deg);
  & path {
    stroke: $i-day-grey-4;
  }

  @media (min-width: $breakpoint-xl) {
    margin-right: $space;
    margin-left: $space;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.breadcrumbText {
  color: $i-day-grey-0;
  text-align: center;
  padding-left: 8px;

  &.current {
    color: $i-day-black;
    font-weight: 500;
  }
}

.coin {
  height: $space-3;
  width: $space-3;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $i-day-primary-white;

  font-weight: bold;
  line-height: $space-3;

  border-radius: 50%;

  &.regular {
    background-color: $i-day-grey-1;
  }

  &.current {
    background-color: $i-day-grey-0;
  }

  &.completed {
    background-color: $i-day-primary-green;

    &.current {
      background-color: $i-day-primary-green-darker;
    }
  }

  &.rejected {
    background-color: $i-day-primary-red;

    &.current {
      background-color: $i-day-primary-red-darker;
    }
  }
}

.checkIcon {
  width: $space-2-alternative;
  height: $space-2-alternative;
}

.xIcon {
  width: $space-2;
  height: $space-2;
}
