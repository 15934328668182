@import "../../styles/theme.scss";

.navigationList {
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  row-gap: $space;

  &.collapsed {
    max-width: 63px;
  }
}
