@import "~styles/theme.scss";

.listItem {
  margin-bottom: $space;
  padding: $space-2 $space-3;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $i-day-primary-white;
  border-radius: $input-border-radius;
  border: 1px solid $i-day-grey-6;
  word-break: break-all;

  .content {
    margin-right: auto;
  }

  .title {
    margin-bottom: 4px;
  }

  .text {
    margin-bottom: 0px;
    color: $i-day-grey-1;
  }
}
