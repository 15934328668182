@import "../../../styles/theme.scss";

.screenWrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
  @media (min-width: $breakpoint-sm) {
    padding: 16px;
    background-color: #e0e0e0;
    min-height: 100vh;
  }
  background-color: #f9f8f9;
}

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.singleScreenWrapper {
  border-radius: 0 0 8px 8px !important;
  background-color: #f9f8f9;
  max-height: fit-content;
  margin-top: 0 !important;
  width: 100%;
  flex-grow: 1;
  @media (min-width: $breakpoint-sm) {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  @media (max-width: $breakpoint-sm) {
    max-height: 100vh;
  }
}

.headerWrapper {
  background: $i-day-primary-white;
  width: 100%;
  border-bottom: 1px solid $i-day-grey-6;
  @media (min-width: $breakpoint-sm) {
    border-radius: 8px 8px 0 0;
  }
  padding: 32px 40px;
  @media (max-width: $breakpoint-sm) {
    padding: 16px;
  }
}

.titleWrapper {
  display: flex;
  gap: 6px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-sm) {
    margin-bottom: $space;
    margin-right: auto;
    padding-right: 16px;

    flex: 1;
    width: 100%;
  }
}

.title {
  font-weight: 700;
  letter-spacing: -0.02em;

  margin: 0;
  font-family: Inter, sans-serif;
  color: $i-day-grey-0;
  font-style: normal;
  font-size: 26px;
  line-height: 31px;
}

.subtitle {
  font-weight: 400;
  letter-spacing: -0.52px;

  margin: 0;
  font-family: Inter, sans-serif;
  color: $i-day-grey-0;
  font-style: normal;
  font-size: 26px;
  line-height: 31px;

  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}
