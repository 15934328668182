@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.leftDetails {
  padding: 0 15px;

  .leftDetailsRow {
    display: flex;
    border-bottom: 1px solid $i-day-grey-5;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      border-bottom: none;
    }

    .leftDetailsBanner {
      width: 100%;
      @media (min-width: 900px) {
        width: 50%;
      }
      h4 {
        color: $i-day-grey-2;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      p {
        font-weight: 500;
        margin-bottom: 0;
      }
      &:nth-child(2) {
        border-left: 1px solid $i-day-grey-5;
        padding-left: 15px;
      }
    }
  }
}
