@import "../../../styles/theme.scss";

.updatePasswordContainer {
  & :global(.ant-modal-content) {
    border-radius: 12px;
  }

  & :global(.ant-input-password) {
    height: 44px;
    border-radius: 6px;
  }

  & :global(.ant-input-affix-wrapper:hover) {
    border-color: $i-day-grey-0 !important;
  }
  & :global(.ant-input-affix-wrapper-focused) {
    border-color: $i-day-grey-0 !important;
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
  }
}
