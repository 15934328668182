.markdownWrapper {
  & img {
    max-width: 100% !important;
    height: auto;
  }
}

.p {
  white-space: pre-wrap;
}
