@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.secondary {
  margin-top: $space;
  display: flex;
  align-items: center;
  column-gap: $space;
  color: $i-day-grey-2;
}

.driverpayIndicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;

  &.disabled {
    background-color: $i-day-grey-3;
  }

  &.enabled {
    background-color: $i-day-primary-green-lightest;
  }
}

.bookingStatusSection {
  @media (max-width: 400px) {
    padding-left: 0 !important;
  }
  & h2 {
    text-align: right;
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    text-align: right;
    color: $i-day-grey-0;
  }
  margin-left: auto;
}

.bookingStatus {
  @include mixin.flex(center, center);
  font-size: 12px;
  padding: 4px 8px;
  margin-top: 4px;
  border-radius: 6px;
  width: fit-content;
  margin-left: auto;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  @media (max-width: 520px) {
    min-width: 83px;
  }
}
