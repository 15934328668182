@import "../../styles/theme.scss";

.label {
  display: block;

  color: $i-day-grey-0;

  font-size: 11px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;

  @media (max-width: $breakpoint-sm) {
    font-size: 13px;
  }
}
