@import "../../styles/theme.scss";

$icon-width: $space-3-alternative;

.wrapper {
  position: relative;
}
.customSelect {
  &:global(.ant-select) {
    width: 100%;
    &:global(.ant-select:not(.ant-select-disabled)):hover
      :global(.ant-select-selector) {
      border-color: $i-day-grey-0;
      outline: $i-day-grey-0;
    }

    & :global(.ant-select-selector) {
      border: 1px solid $i-day-grey-4;
      border-radius: 6px !important;
    }

    &:global(.ant-select-focused:not(.ant-select-disabled)):global(
        .ant-select:not(.ant-select-customize-input)
      )
      :global(.ant-select-selector) {
      border: 1px solid $i-day-grey-4;
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2);
    }
  }

  &.hasIcon {
    :global(.ant-select-selection-item) {
      display: flex;
      align-items: center;
      padding-left: 6px;
    }
  }
}
.customDropdown {
  border-radius: 4px;
  z-index: 3000 !important;

  :global(
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    ) {
    background-color: $i-day-primary-green-lightest;
  }
  :global(.ant-select-item-option-content) {
    display: flex;
    align-items: center;
    padding-left: 6px;
  }
}

.icon {
  width: $icon-width;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  pointer-events: none;
}
