@import "../../../styles/theme.scss";

.row {
  display: flex;
  gap: $space-3;

  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

.form {
  padding: $space-3 0 $space-4;
}
