@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.saveButton {
  width: calc(100% - 40px);
  border-radius: 6px;
  height: 44px;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 1px;
  background: $i-day-primary-green;
  border-radius: 32px;
  margin: 8px 10px 10px;
  justify-content: center;

  & span {
    color: $i-day-primary-white !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.005em;
  }
  &:hover {
    background: $i-day-primary-green;
  }
}