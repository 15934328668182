@import "../../../../styles/theme.scss";

.container {
  box-sizing: border-box;

  width: 30px;
  height: 30px;
  padding: 5px;

  border: 1px solid $i-day-grey-6;
  border-radius: 50%;
}

.inline {
  display: inline-block;
}

.success {
  color: $i-day-primary-green;
}

.warning {
  color: $i-day-primary-yellow;
}

.error {
  color: $i-day-primary-red-darker;
}

.waiting {
  color: #5603ad;
}

.absent {
  color: #d3c0d2;
}

.icon {
  width: 100%;
  height: 85%; // 100% is too high
}
