@import "~styles/theme.scss";

.dashboard {
  height: 100%;
  overflow-y: scroll;
  background-color: $i-day-grey-6;

  &__header {
    position: relative;
    padding: $space-5 $space-4 $space-4;
    margin-bottom: $space-2;
    display: flex;
    align-items: center;
    background-color: $i-day-primary-white;
    border-radius: $space;

    @media (max-width: $breakpoint-sm) {
      padding: $space-2;

      [class*="menuToggle"] {
        margin-right: $space-2;
      }
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
  }

  &__inner {
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__dropdown {
    order: 4;
  }

  &__picker {
    margin-right: $space-2;
    order: 3;

    @media (max-width: 600px) {
      order: 4;
      margin-right: 0;
      margin-top: $space-2;
      width: 100%;
      display: block;

      button {
        width: 100%;
      }
    }
  }

  &__topStat {
    [class*="dashboardItem__amount"] {
      font-size: 140px;
      line-height: 170px;
      margin-bottom: $space-3;

      @media (max-width: 700px) {
        justify-content: center;
        font-size: 58px;
        line-height: 70px;
        margin-bottom: $space-2;
      }
    }
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    gap: $space-4;
    margin-top: $space-4;

    @media (max-width: 1100px) {
      justify-content: center;
    }
  }

  &__stat {
    width: calc(33% - $space-4);
    flex-grow: 1;

    @media (max-width: 1100px) {
      width: 47%;
      max-width: 380px;
    }

    @media (max-width: 1100px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__empty {
    height: 720px;
  }
}
