// Alternatives may be used in vertical paddings
$space: 8px;
$space-alternative: 4px;

$space-2: 16px;
$space-2-alternative: 12px;

$space-3: 24px;
$space-3-alternative: 20px;

$space-4: 32px;
$space-5: 40px;
$space-6: 48px;
$space-7: 56px;
$space-8: 64px;
