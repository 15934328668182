@use "../../styles/colors.scss" as color;
@use "../../styles/mixins.scss" as mixin;
@import "../../styles/theme.scss";

.customCheckbox {
  &:global(.ant-checkbox-wrapper) {
    :global(.ant-checkbox-input:focus) + :global(.ant-checkbox-inner),
    :global(.ant-checkbox-wrapper:hover) :global(.ant-checkbox-inner),
    :global(.ant-checkbox:hover) :global(.ant-checkbox-inner),
    &:global(:hover) :global(.ant-checkbox-inner) {
      border-color: color.$primary-color !important;
    }
    :global(.ant-checkbox-checked) {
      :global(.ant-checkbox-inner) {
        background-color: color.$primary-color;
        border-color: color.$primary-color;
      }
    }
    :global(.ant-checkbox-checked:after) {
      border-color: $i-day-grey-0;
    }
  }
}
