@import "../../styles/theme.scss";

.container {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 48px;
  border: 1px solid $i-divider-color;
  border-radius: 6px;
  background-color: $i-day-primary-white;

  @media (max-width: $breakpoint-sm) {
    height: 56px;
  }
}

.countrySelector {
  position: relative;
  cursor: pointer;
  padding: 14px 12px;
  min-width: 128px;
  display: flex;
  align-items: center;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: $i-day-grey-0;
    margin: 0 8px;
  }
  border-right: 1px solid $i-divider-color;
  height: 100%;
}

.countrySelectorWrapper {
  & :global(.ant-popover-inner-content) {
    padding: 0 !important;
    min-width: 128px;
  }

  & :global(.ant-popover-arrow) {
    display: none;
  }

  & :global(.ant-popover-placement-right) {
    padding-left: 0;
  }
}

.countrySelectorListElement {
  padding: 12px 16px;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: $i-day-grey-0;
    margin: 0;
    margin-left: 8px;
  }
  display: flex;
  align-items: center;
  border-bottom: 1px solid $i-divider-color;
  cursor: pointer;
  &:hover {
    background-color: $i-divider-color;
  }
}

.numberInput {
  border-radius: 0px !important;
  border: none !important;
  padding-left: 4px !important;
  width: 100% !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border: 1px solid transparent !important;

  &:hover,
  &:focus {
    border-color: $i-day-grey-0 !important;
    outline: $i-day-grey-0 !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2) !important;
  }
  padding-left: 16px !important;

  display: flex !important;
  align-items: center;
  & :global(.ant-input-number-handler-wrap) {
    display: none;
  }
}

.popoverWrapper {
  max-height: 300px;
  overflow: auto;
}
