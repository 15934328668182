@import "../../styles/theme.scss";

.modalWrapper {
  :global(.ant-modal-content) {
    border-radius: 12px;
  }
  :global(.ant-modal-header) {
    border-radius: 12px 12px 0 0;
  }
}
