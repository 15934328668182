@import "../../styles/theme.scss";

.burgerButton {
  padding: 0;
  border: 0;
  background: $i-day-primary-white;

  display: flex;
  border-radius: 50%;
  cursor: pointer;

  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: $i-day-grey-3;
  }

  &:active {
    background-color: $i-day-grey-2;
  }
}

.withBadge {
  position: relative;
}

.badge {
  position: absolute;
  top: -4px;
  right: -4px;

  min-width: 18px;
  max-width: 33px;
  height: 18px;

  padding: 0 4px;

  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 9px;

  opacity: 0.8;
}

.badgeDanger {
  color: $i-day-primary-white;
  background-color: $i-day-primary-red-darker;
}

.badgeWarning {
  background-color: $i-day-primary-yellow-darker;
}
