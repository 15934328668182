@import "../../styles/theme.scss";

.stripeConfirmModal {
  & * {
    font-family: Inter, sans-serif;
  }

  :global(.ant-drawer-content-wrapper) {
    height: 100vh !important;
  }

  :global(.ant-modal-content) {
    border-radius: 12px;
  }

  :global(.ant-modal-body) {
    padding: 0 !important;
  }

  .selectAnotherCompanyWrapper {
    width: 100%;
    padding: 0 24px;
  }

  .selectAnotherProfile {
    margin-bottom: 0;
    margin-top: 12px;
    text-align: center;
  }
}

.confirmationModalSelect {
  z-index: 9999;
}

.stripeModalMobile {
  position: fixed;
  left: 0;
  top: 0;
  height: 100svh;
  width: 100vw;
  overflow: auto;
  background-color: $i-day-primary-white;
}

.overflowHidden {
  position: fixed;
  height: 0;
  overflow: hidden;
}
