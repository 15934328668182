@use "../../styles/colors.scss" as color;
@import "../../styles/theme.scss";

.customRadioGroup {
  display: flex;
}

.customRadioItem {
  & :global(.ant-radio-checked) :global(.ant-radio-inner),
  & :global(.ant-radio:hover) :global(.ant-radio-inner) {
    border-color: color.$primary-color;
  }
  & :global(.ant-radio-inner:after) {
    background-color: color.$primary-color;
  }
  & :global(.ant-radio-input:focus) + :global(.ant-radio-inner) {
    box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
    border-color: color.$primary-color;
  }
}
