@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.fleet-wrapper {
  min-height: calc(85vh - 67px);
  .sub-top-wrapper {
    padding: 0 32px 32px;
    margin-top: 32px;
  }
  .fleet-form-wrapper {
    padding-top: 50px;
    height: calc(85vh - 67px);
    overflow-y: auto;
    background-color: $i-day-grey-8;

    @media (max-width: 850px) {
      & > .ant-form,
      .ant-alert {
        width: 100% !important;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      & > .ant-alert {
        margin-left: 1rem;
        margin-right: 1rem;
        width: calc(100% - 2rem) !important;
      }
    }

    @include mixin.flex(center, flex-start, column);
    .ant-alert {
      width: 45em;
    }
    .ant-form {
      width: 45em;
      margin-top: 25px;
      .ant-form-item-label > label {
        color: $i-day-grey-1;
        text-transform: uppercase;
      }
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-select-selector {
        height: 44px;
        border-radius: 6px;
        @include mixin.flex(center, flex-start);
        .ant-input {
          height: auto;
        }
      }
      .ant-input-group-wrapper-status-success {
        .ant-input-group-addon {
          border-left: 1px solid #d9d9d9;
        }
      }
      .ant-input-group-wrapper-status-error {
        .ant-input-group-addon {
          border-left: 1px solid #ff4d4f;
        }
      }
      .ant-input-group-addon {
        border-radius: 6px;
        cursor: pointer;
        background-color: $i-day-primary-white;
        &:hover {
          background-color: #f4f4f4;
        }
        .copy-btn {
          @include mixin.flex(center, center);
        }
      }

      .app-secret-input {
        @include mixin.flex(center, center);
        div {
          @include mixin.flex(center, center);
          padding: 10px 20px;
          border: 1px solid #d9d9d9;
          margin-left: 20px;
          border-radius: 6px;
          border-color: $i-day-primary-green;
          color: $i-day-primary-green;
          cursor: pointer;

          &:hover {
            background-color: $i-day-primary-green;
            color: $i-day-primary-white;
            transition: 0.3s;
          }
        }
      }
      .ant-btn {
        @include mixin.flex(center, center);
        height: 50px;
        width: 100px;
        color: $i-day-primary-white;
        background-color: $i-day-primary-green;
        border-color: $i-day-primary-green;
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 6px;
      }
    }
  }
}
