@import "../../styles/theme.scss";

.tableItem {
  background: $i-day-grey-8;
  border: 1px solid $i-day-grey-5;
  border-radius: 6px;
  padding: 24px;
}

.tableField {
  border-top: 1px solid $i-day-grey-5;
  padding: 12px 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  & > p {
    font-family: Inter, sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0 !important;
    font-size: 13px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $i-day-grey-2;
  }
  & > span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: $i-day-grey-0;
    margin-left: auto;
  }
}

.tableHeader {
  padding-bottom: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  & > div:last-child {
    margin-left: auto;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  & p {
    margin: 0 !important;
    margin-left: 10px !important;
  }
}

.bold {
  font-weight: 700 !important;
}
