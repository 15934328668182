@import "~styles/theme.scss";

.dashboardItem {
  padding: $space-3;
  background-color: $i-day-primary-white;
  border-radius: $space;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $i-day-grey-5;
    padding-bottom: $space-2;
    margin-bottom: $space-2;
  }

  &__title {
    margin: 0px 0px $space;
    font-size: 15px;
    line-height: $space-3;
    font-weight: 600;
    color: $i-day-grey-0;
  }

  &__subtitle {
    margin-bottom: $space-2;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
  }

  &__amount {
    margin: 0px 0px $space;
    display: block;
    text-align: center;
    font-size: 58px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -0.01em;
    color: $i-day-grey-0;
  }

  &__link {
    display: block;
    border-top: 1px solid $i-day-grey-5;
    margin: $space-3 auto 0px;
    padding-top: $space-2;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: $i-day-grey-0;

    &:hover {
      color: lighten($i-day-grey-0, 20%);
    }
  }
}
